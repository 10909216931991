import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Image from '../../util/Image';
import home from '../../assets/images/home/home.json';

const About = () => {
  const { aboutImageSrc, aboutImageAlt } = home.about;
  return (
    <section className="about">
      <Image className="about-image" filename={aboutImageSrc} alt={aboutImageAlt} />
      <article className="about-article">
        <h2>
          <FormattedMessage id="about.title" />
        </h2>
        <p className="about-paragraph">
          <FormattedMessage id="about.paragraph1" />
        </p>
        <p className="about-paragraph">
          <FormattedMessage id="about.paragraph2" />
        </p>
        <p className="about-paragraph">
          <FormattedMessage id="about.paragraph3" />
        </p>
        <p className="about-paragraph">
          <FormattedMessage id="about.paragraph4a" />
          <a href="https://www.spoonflower.com/profiles/sugarly_designs?sub_action=designs" aria-label="Spoonflower" target="_blank" rel="noopener noreferrer">
            {' '}
            <FormattedMessage id="about.paragraph4link" />
          </a>
          <FormattedMessage id="about.paragraph4b" />
        </p>
        <p className="about-paragraph">
          <FormattedMessage id="about.paragraph5" />
        </p>
      </article>
    </section>
  );
};

export default About;
