import React from 'react';
import PropTypes from 'prop-types';
import HeroImage from '../HeroImage/HeroImage';
import About from '../About/About';
import Newsletter from '../Newsletter/Newsletter';
import InstaFeed from '../InstaFeed/InstaFeed';

// const Home = ({ frontmatter, instaData }) => {
// const { edges } = instaData;
const Home = () => (
  <>
    <HeroImage />
    <About />
    <Newsletter />
    <InstaFeed />
  </>
);
Home.propTypes = {
  frontmatter: PropTypes.shape({
    pageKey: PropTypes.string.isRequired,
    seo_title: PropTypes.string.isRequired,
    seo_desc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  // instaData: PropTypes.array.isRequired,
};

export default Home;
