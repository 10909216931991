import React from 'react';
import Image from '../../util/Image';
import data from '../../assets/images/instagram/instagram.json';
import Icon from '../../assets/icons';

const nodeUrl = 'https://www.instagram.com/p/';

const instaImages = [
  { id: 'CBEB1Pbnk9K', src: data.instagramA.instaImageSrc, alt: data.instagramA.instaImageAlt },
  { id: 'CBDsOIDnkUW', src: data.instagramB.instaImageSrc, alt: data.instagramB.instaImageAlt },
  { id: 'CBDZHFonmur', src: data.instagramC.instaImageSrc, alt: data.instagramC.instaImageAlt },
  { id: 'CBBXLEen2Ec', src: data.instagramD.instaImageSrc, alt: data.instagramD.instaImageAlt },
  { id: 'CBBF59TngG5', src: data.instagramE.instaImageSrc, alt: data.instagramE.instaImageAlt },
  { id: 'CBAvbqxn2WS', src: data.instagramF.instaImageSrc, alt: data.instagramF.instaImageAlt },
];

const InstaFeed = (props) => (
  <section className="instagram-feed">
    {instaImages.map((image) => (
      <a href={nodeUrl + image.id} key={image.id} aria-label="Instagram" target="_blank" rel="noopener noreferrer" className="insta-link">
        <Image filename={image.src} alt={image.alt} className="insta-image" />
        <div className="image-overlay">
          <Icon name="Instagram" className="insta-icon" alt="instagram" />
        </div>
      </a>
    ))}
  </section>
);


export default InstaFeed;

// import React from 'react';
// import Img from 'gatsby-image';

// const InstaFeed = (props) => {
//   const { images } = props;
//   const sortedImages = images.sort((a, b) => ((a.node.timestamp > b.node.timestamp) ? 1 : -1));
//   console.log(images);
//   return (
//     <section className="instagram-feed">
//       {sortedImages.slice(6).map((image) => (
//         <figure className="insta-image" key={image.node.id} style={{ width: '100%' }}>
//           <Img fluid={image.node.localFile.childImageSharp.fluid} />
//         </figure>
//       ))}

//     </section>
//   );
// };


// export default InstaFeed;
