import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Image from '../../util/Image';
import home from '../../assets/images/home/home.json';

const HeroImage = () => {
  const { heroImageSrc, heroImageAlt } = home.home;
  return (
    <section className="hero-image">
      <Image filename={heroImageSrc} alt={heroImageAlt} />
      <h2 className="hero-title">
        <FormattedMessage
          id="home.title"
          values={{
            br: <br className="line-break" />,
          }}
        />
      </h2>
    </section>
  );
};


export default HeroImage;
