import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../layouts/main/Layout';
import Home from '../components/Home/Home';
import SEO from '../util/SEO/SEO';

const IndexPage = ({ ...props }) => {
  const intl = useIntl();
  console.log(intl.locale);
  const { data } = props;
  const { homePageData } = data;
  const { frontmatter } = homePageData;
  const { originalPath } = props.pathContext.intl;
  return (
    <Layout originalPath={originalPath}>
      <SEO
        locale={intl.locale}
        title={intl.formatMessage({ id: 'home.SEOtitle' })}
        description={intl.formatMessage({ id: 'home.SEODescription' })}
        keywords={intl.formatMessage({ id: 'SEOkeywords.keywords' })}
      />
      <Home frontmatter={frontmatter} />
    </Layout>
  );
};


// we should make prop-types shape in separate file
IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    homePageData: PropTypes.shape({
      frontmatter: PropTypes.shape({
        pageKey: PropTypes.string.isRequired,
        seo_title: PropTypes.string.isRequired,
        seo_desc: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }).isRequired,
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
export default IndexPage;
export const pageQuery = graphql`
  query HomeContent($locale: String) {
    homePageData: markdownRemark(
      frontmatter: { pageKey: { eq: "page_home" }, locale: { eq: $locale } }
    ) {
      fields {
        slug
      }
      frontmatter {
        pageKey
        seo_title
        seo_desc
        title
        text
      }
    }
  }
`;
