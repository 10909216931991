/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Spinner from '../Spinner/Spinner';
import Icon from '../../assets/icons';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(<FormattedMessage id="newsletter.emailErrorInvalid" />)
    .required(<FormattedMessage id="newsletter.emailErrorRequired" />),
});

const Newsletter = () => {
  const [isResolved, setResolving] = useState(false);
  const [isRejected, setRejecting] = useState(false);
  const onSubmit = async (values, {
    setSubmitting, resetForm,
  }) => {
    const { email } = values;
    try {
      await addToMailchimp(email);
      setResolving(true);
      setTimeout(() => {
        setResolving(false);
      }, 2000);
      resetForm({});
      setSubmitting(false);
    } catch (error) {
      setRejecting(true);
      setTimeout(() => {
        setRejecting(false);
      }, 2000);
      setSubmitting(false);
    }
  };

  let submitButtonClass = 'btn btn-primary';
  let submitButtonContent = <FormattedMessage id="newsletter.subscribe" />;

  if (isResolved) {
    submitButtonClass = 'btn btn-success';
    submitButtonContent = <Icon name="Tick" className="submit-icon" />;
  } else if (isRejected) {
    submitButtonClass = 'btn btn-error';
    submitButtonContent = <Icon name="Close" className="submit-icon" />;
  } else {
    submitButtonClass = 'btn btn-primary';
    submitButtonContent = <FormattedMessage id="newsletter.subscribe" />;
  }

  return (
    <section className="newsletter">
      <h2>
        <FormattedMessage id="newsletter.header" />
      </h2>
      <p className="newsletter-text">
        <FormattedMessage id="newsletter.description" />
      </p>

      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          isSubmitting,
          touched,
          errors,
          handleSubmit,
        }) => (
          <Form
            className="newsletter-form"
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              <div className="field-group">
                <Field
                  type="text"
                  name="email"
                  placeholder="Email"
                  className="newsletter-input"
                  aria-label="Newsletter input"
                />
                {touched.email && errors.email && <span className="error newsletter-error">{errors.email}</span>}
              </div>
              <button aria-label="subscribe" className={isSubmitting ? 'btn btn-primary' : submitButtonClass} type="submit" name="submit">
                {isSubmitting ? <Spinner />
                  : submitButtonContent}
              </button>
            </div>

          </Form>
        )}
      </Formik>
    </section>
  );
};


export default Newsletter;
